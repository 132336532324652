import React, { useRef } from 'react';
import ProfilePicture from "routes/app/components/ProfilePicture"
import { BsPlusCircle } from "react-icons/bs";

const ImageUploader = ({ onImageSelect, disabled, userId, count }) => {    
  const fileInputRef = useRef();

  // Handle profile image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onImageSelect(reader.result); // Pass the selected image to parent component
      };
      reader.readAsDataURL(file);
    }
  };

  // Trigger the file input when the image is clicked
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="relative flex flex-col items-center">
        <label className="block font-semibold mb-1">Profile Image</label>
        <div className='relative flex flex-col justify-center items-center cursor-pointer' onClick={handleImageClick}>
            <BsPlusCircle size={35} color="black" className='bg-white stroke-[1px] rounded-full absolute bottom-0 right-0 translate-x-[5%] translate-y-[5%] z-20'/>
            <ProfilePicture userId={userId} count={count}/>
        </div>
      <input
        type="file"
        ref={fileInputRef} // Reference to the hidden file input
        style={{ display: 'none' }} // Hide the actual file input
        accept="image/*" // Only accept image files
        onChange={handleImageChange} // Handle file selection
        disabled={disabled} // Disable if necessary
      />
    </div>
  );
};

export default ImageUploader;
