import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(null);
    setError(null);

    // Get the Cloud Function reference
    const functions = getFunctions();
    const sendContactEmail = httpsCallable(functions, "sendContactEmail");

    try {
      const result = await sendContactEmail(formData);
      if (result.data.success) {
        console.log(result.data.message); 
        setSuccess("Message sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      }
    } catch (err) {
      setError("Failed to send message. Please try again.");
      console.error("Error sending message:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto my-8 p-8 bg-white rounded shadow-lg w-[90%] max-w-[500px]">
      <h2 className="text-2xl font-semibold text-center">
        Contact Us
        </h2>
        <h3 className="text-lg font-semibold mb-6 text-center">
            contact@ride-share.app
        </h3>
      {success ? (
        <div className="alert alert-success text-center text-white">
          {success}
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          {error && <div className="alert alert-error">{error}</div>}
          <div className="form-control">
            <label className="label">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="input input-bordered w-full"
              placeholder="Your name"
              required
            />
          </div>
          <div className="form-control">
            <label className="label">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="input input-bordered w-full"
              placeholder="Your email"
              required
            />
          </div>
          <div className="form-control">
            <label className="label">Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="textarea textarea-bordered w-full"
              placeholder="Your message"
              required
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary w-full"
            disabled={loading}
          >
            {loading ? "Sending..." : "Send Message"}
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
