import React from 'react';
import { Link } from 'react-router-dom';
import HowItWorks from './components/HowItWorks';
import Notifications from './components/Notifications'
import mainImg from 'assets/images/home/hero.jpg';

const Hero = ()=>{
    return(
        <div className="relative w-full" style={{
            backgroundImage: `url(${mainImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        }}>
            <div className='relative flex flex-col justify-center items-center text-white py-20 text-center z-20'>
                <div className='relative z-20'>
                    <h3 className="text-3xl font-bold mb-4">Ready for your next ride?</h3>
                    <Link to="/create-trip">
                        <button className="btn btn-warning btn-md px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
                            Post a Trip
                        </button>
                    </Link>
                </div>        
                <div className='bg-blue-900 absolute w-[70%] h-[30%] z-10 blur-3xl'/>
            </div>
            <div className='bg-blue-900 absolute top-0 right-0 w-full h-full z-10 opacity-50'/>
        </div>
    )
}
const Home = () => {
  return (
    <div className="min-h-screen relative w-full flex flex-col">
        <Hero/>
        <HowItWorks/>
        <Notifications/>
    </div>
  )
}

export default Home;
