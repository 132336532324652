// src/components/PicturesUpload.js
import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { fetchUserImages } from 'utils/imageUtils';
import PictureSlot from './PictureSlot';

const PicturesUpload = () => {
  const [pictures, setPictures] = useState(Array(10).fill(null));
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const init = async () => {
      if (!user) return;
      const fetchedPictures = await fetchUserImages(user.uid);
      const updatedPictures = [...fetchedPictures, ...Array(10 - fetchedPictures.length).fill(null)];
      setPictures(updatedPictures);
    };

    init();
  }, [user]);

  const handleUploadComplete = (url) => {
    setPictures((prevPictures) => {
      // Add the new image URL to the first available null slot, keeping placeholders
      const updatedPictures = [...prevPictures];
      const firstNullIndex = updatedPictures.findIndex((picture) => picture === null);
      if (firstNullIndex !== -1) {
        updatedPictures[firstNullIndex] = url;
      }
      return updatedPictures;
    });
  };

  const handleDeleteComplete = (url) => {
    setPictures((prevPictures) => {
      // Filter out the deleted image by URL and shift images to close the gap
      const updatedPictures = prevPictures.filter((picture) => picture !== url);
      // Fill remaining slots with null to maintain 10 slots
      return [...updatedPictures, ...Array(10 - updatedPictures.length).fill(null)];
    });
  };

  return (
    <div className="w-full">
        <label className="block font-semibold mb-1">Pictures Gallery</label>
      <div className="w-full flex flex-wrap gap-2 justify-center items-center">
        {pictures.map((url, index) => (
          <PictureSlot
            key={index}
            url={url}
            isNext={index === pictures.findIndex((picture) => picture === null)}
            userId={user.uid}
            onUploadComplete={handleUploadComplete}
            onDeleteComplete={handleDeleteComplete}
          />
        ))}
      </div>
    </div>
  );
};

export default PicturesUpload;
