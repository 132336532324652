import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { BiZoomIn, BiRotateRight } from "react-icons/bi"; // Scale and Rotate icons

const ImageCropper = ({ image, onCropComplete, onCancel }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0); // State for rotation
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const getCroppedImg = async () => {
    const createImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", (error) => reject(error));
        img.setAttribute("crossOrigin", "anonymous");
        img.src = url;
      });
    };

    const imageElement = await createImage(image);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const width = 300;
    const height = 300;
    canvas.width = width;
    canvas.height = height;

    // Apply rotation
    ctx.translate(width / 2, height / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-width / 2, -height / 2);

    ctx.drawImage(
      imageElement,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      width,
      height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, "image/jpeg");
    });
  };

  const handleSave = async () => {
    setIsUploading(true);
    const croppedBlob = await getCroppedImg();

    const auth = getAuth();
    const user = auth.currentUser;
    const storage = getStorage();
    const storageRef = ref(storage, `users/${user.uid}/profile.jpg`);

    const uploadTask = uploadBytesResumable(storageRef, croppedBlob);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.error("Upload failed:", error);
        setIsUploading(false);
      },
      () => {
        onCropComplete();
        setIsUploading(false);
      }
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-md shadow-lg w-[300px]">
        <div className="relative w-full h-60">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            rotation={rotation} // Pass rotation to Cropper
            aspect={1}
            cropShape="round"
            showGrid={false}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onRotationChange={setRotation} // Add rotation change handler
            onCropComplete={(_, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
          />
        </div>
        <div className="flex flex-col items-center mt-4 space-y-4">
          {/* Zoom Slider */}
          <div className="flex items-center w-full">
            <BiZoomIn className="text-xl mr-2" />
            <input
              type="range"
              min="1"
              max="3"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
              className="w-full"
            />
          </div>
          {/* Rotation Slider */}
          <div className="flex items-center w-full">
            <BiRotateRight className="text-xl mr-2" />
            <input
              type="range"
              min="0"
              max="360"
              step="1"
              value={rotation}
              onChange={(e) => setRotation(e.target.value)}
              className="w-full"
            />
          </div>
          <button
            onClick={handleSave}
            className={`btn btn-primary mt-4 w-full ${isUploading ? "opacity-50" : ""}`}
            disabled={isUploading}
          >
            {isUploading ? "Uploading..." : "Save"}
          </button>
          <button
            onClick={onCancel}
            className="btn btn-secondary mt-2 w-full"
            disabled={isUploading}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
