import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, Link } from 'react-router-dom';

import ScrollToTop from "utils/ScrollToTop";
import FooterRoutes from 'routes/FooterRoutes';
import Logo from "components/Logo";
import Footer from 'components/Footer';

import ResetPassword from "routes/pre/pages/ResetPassword.jsx"
import EditProfilePage from 'routes/app/pages/EditProfilePage/EditProfilePage.js';
import Auth from './pages/Auth';
import EmailVerification from './pages/EmailVerification';
import TermsForm from './pages/TermsForm'

const BackButton = ()=>{
    return(
        <Link to="/">
            <button className='btn btn-secondary'>
                Back
            </button>
        </Link>   
    )
}
const Content = ({resetPass, isLoggedIn, isEmailVerified, redirectToEditProfile, termsAccepted})=>{
    const location = useLocation();
    let route;
    if(resetPass) route = <ResetPassword/>;
    else if(!isLoggedIn) route = <Auth/>;
    else if (isLoggedIn && !isEmailVerified) route = <EmailVerification/>;
    else if (redirectToEditProfile) route = <EditProfilePage init={true}/>;
    else if(!termsAccepted) route = <TermsForm/>;

    return(
        <>
            <ScrollToTop />
            <div className="relative min-h-screen flex flex-col bg-gray-100">
                <div className='flex flex-col justify-center items-center'>
                    <Logo/>
                    {location.pathname !== '/' && <BackButton/>}
                    <Routes>
                        <Route path="/" element={route} />
                        {FooterRoutes}
                        <Route path="*" element={
                            <Navigate
                                to={{
                                    pathname: "/",
                                    search: location.search,
                                }}
                                replace
                            />
                        }/>
                    </Routes> 
                </div>
                <Footer/>
            </div>
        </>
    )
}

const PreRoutesLayout = ({resetPass, isLoggedIn, isEmailVerified, redirectToEditProfile, termsAccepted}) => {
    return (
        <Router>
            <Content resetPass={resetPass} isLoggedIn={isLoggedIn} isEmailVerified={isEmailVerified} redirectToEditProfile={redirectToEditProfile} termsAccepted={termsAccepted}/>
        </Router>
    )
}

export default PreRoutesLayout