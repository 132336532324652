import React, { useState, useEffect } from 'react';
import { getAuth, signOut, applyActionCode } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useSearchParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import animationData from 'assets/lottie/email.json';

const sendEmailVerification = httpsCallable(getFunctions(), 'sendEmailVerification');

const EmailVerification = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState();
  const [loadingOobCode, setLoadingOobCode] = useState(true); // Loading state for oobCode verification
  const [loadingEmail, setLoadingEmail] = useState(false); // Loading state for email sending
  const [searchParams] = useSearchParams();

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const code = searchParams.get('oobCode');
    if (code) {
      verifyEmail(code);
    } else {
      setLoadingOobCode(false);
    }
  }, [searchParams]);

  const verifyEmail = async (code) => {
    setError(null);
    try {
      await applyActionCode(auth, code);
      window.open('/', '_self'); // Redirect to home after successful verification
    } catch (error) {
      setError('Oops! The verification link is invalid or expired.');
      setLoadingOobCode(false);
    }
  };

  const handleResendVerification = async () => {
    setError('');
    setLoadingEmail(true);
    try {
      await sendEmailVerification();
      setEmailSent(true);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoadingEmail(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // If we are verifying the oobCode, show loading
  if (loadingOobCode) {
    return <p>Verifying your email...</p>;
  }

  return (
    <div className="flex flex-col justify-center items-center max-w-[700px] bg-white p-8 rounded shadow-md text-center">
        {error && <div className="alert alert-error mb-4">{error}</div>}
        <Lottie
            className='w-[200px]'
            animationData={animationData}
            loop={true}
        />
        <p className="mb-6">
          Hey there! We just sent a shiny new verification link to <strong>{user?.email}</strong>.
          <br />
          Please check your inbox to verify your email address. (Psst... sometimes it sneaks into the <strong>Spam</strong> or <strong>Promotions</strong> folder, so be sure to check there too!)
        </p>
        {emailSent ? (
          <p className="text-green-500 mb-4">
            🎉 Hooray! The verification email has been sent. Go check your inbox (or maybe your spam folder)!
          </p>
        ) : (
          <button
            onClick={handleResendVerification}
            className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 ${
              loadingEmail ? 'cursor-not-allowed opacity-50' : ''
            }`}
            disabled={loadingEmail}
          >
            {loadingEmail ? 'Sending...' : 'Resend Verification Email'}
          </button>
        )}
        <p className="mt-4 text-gray-500 text-sm">
          Need a break? <button onClick={handleLogout} className="underline text-blue-500 hover:text-blue-700">Log out</button> and come back later!
        </p>
      </div>
  );
};

export default EmailVerification;
