import React from 'react';
import { Link } from 'react-router-dom';
import ProfilePicture from "routes/app/components/ProfilePicture"
import Logout from "./Logout";
import ReactCountryFlag from "react-country-flag";
import countries from 'assets/countries.json';

const ProfileDetails = ({ userProfile }) => {
    const getCountryCode = (countryName) => {
        const country = countries.find(c => c.name === countryName);
        return country ? country.code : '';  // Return code or empty string if not found
      };
    
      const countryCode = getCountryCode(userProfile.country);
  const isOwnProfile = userProfile.email // Check if it's the user's own profile

  return (
    <div className="mb-6">
      <div className="flex items-center mb-4 space-x-2">
      {/* Profile image: use fetched image or default */}
      <ProfilePicture userId={userProfile.id}/>
      <div>
        <h1 className="text-3xl font-bold">{userProfile.name}</h1>
        <p className="text-gray-600 flex items-center">
          {countryCode && (
            <ReactCountryFlag
              countryCode={countryCode}
              svg
              style={{
                width: '1.5em',
                height: '1.5em',
                marginRight: '8px',
              }}
            />
          )}
          {userProfile.country}
        </p>
      </div>
    </div>

      {/* description */}
      <p className="text-gray-700">
        {userProfile.description.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>

      {/* If it's the user's own profile, show email */}
      {isOwnProfile && (
        <div className="mt-4 p-4 bg-gray-100 rounded-lg">
          <p className="text-gray-700 font-semibold">Email: {userProfile.email}</p>
          <p className="text-gray-500 text-sm">Only you can see this</p>
        </div>
      )}

      {/* Message or Edit Button */}
      {isOwnProfile ? (
            <div className="flex flex-col justify-start my-6 space-y-4">
                <div className='flex justify-start space-x-4'>
                    <Link to={`/edit-profile`}>
                        <button className="btn btn-primary">Edit Profile</button>
                    </Link>
                    <Link to={`/settings`}>
                        <button className="btn btn-outline">Settings</button>
                    </Link>
                </div>      
                <Logout/>
            </div>
          
        ) : (
            <div className="flex justify-start my-6 space-x-4">
                <Link to={`/chats/user/${userProfile.id}`}>
                    <button className="btn btn-secondary">Message</button>
                </Link>
                <Link to={`/review/${userProfile.id}`}>
                    <button className="btn btn-primary">Write a Review</button>
                </Link>
            </div>
          
        )}
    </div>
  );
};

export default ProfileDetails;
