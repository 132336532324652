import React, { useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import signupImg from 'assets/images/home/signup.svg';
import makeImg from 'assets/images/home/make.svg';
import goImg from 'assets/images/home/go.svg';

const steps = [
  {
    img: signupImg,
    title: 'Post Your Trip',
    text: 'Enter your trip details including starting point, destination, and travel date to find travel companions.'
  },
  {
    img: makeImg,
    title: 'Find a Match',
    text: "After posting your trip you'll get matched with travelers heading in the same direction for long-distance rides or daily commutes."
  },
  {
    img: goImg,
    title: 'Connect, Share, Go!',
    text: 'Chat with your matches to coordinate details, share the cost, and make new friends on the road.'
  }
];

const Step = ({ img, title, text }) => (
  <div className="flex flex-col justify-center items-center bg-white p-6 rounded-lg shadow-lg">
    <div className="relative w-full h-[200px] mb-5">
      <img src={img} className="object-contain w-full h-full" alt={title} />
    </div>
    <h3 className="text-2xl font-semibold mb-4">{title}</h3>
    <p className="text-gray-600">{text}</p>
  </div>
);

const HowItWorks = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="bg-gray-100 py-10">
      <div className="max-w-7xl mx-auto px-4">
        <h2
          className="text-4xl font-bold text-center mb-4 cursor-pointer flex items-center justify-center gap-2"
          onClick={toggleVisibility}
        >
          How to find a ride?
          <FiChevronRight
            className={`transform transition-transform duration-300 ${
              isVisible ? 'rotate-90' : 'rotate-0'
            }`}
            size={28}
          />
        </h2>
        <div
          className={`transition-all duration-300 overflow-hidden ${
            isVisible ? 'py-10 max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'
          }`}
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {steps.map((step) => (
              <Step key={step.title} {...step} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
