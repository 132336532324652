import React, { useRef, useEffect, useState } from 'react';

var markers, map, line;

const MapComponent = ({ setStartCoords, setEndCoords, startCoords, endCoords, startRadius, endRadius, showFull }) => {
    const mapRef = useRef(null);
    const [startMarkerInstance, setStartMarkerInstance] = useState(null);
    const [endMarkerInstance, setEndMarkerInstance] = useState(null);
    const [startCircle, setStartCircle] = useState();
    const [endCircle, setEndCircle] = useState();
    const [startPos, setStartPos] = useState();
    const [endPos, setEndPos] = useState();

    // functions
    const handleMapClick = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        if (!markers[0]) setMarkerPosition({ lat, lng }, true, true);
        else if (!markers[1]) setMarkerPosition({ lat, lng }, false, true);
    };
    const setMarkerPosition = (coords, start, setCoords) => {
        const markerInstance = start?startMarkerInstance:endMarkerInstance;
        const setFunction = start?setStartMarkerInstance:setEndMarkerInstance;
        const setCoordsFunction = start?setStartCoords:setEndCoords;
        const setPosFunctions = start?setStartPos:setEndPos;
        if (markerInstance) markerInstance.setPosition(coords);
        else {
            const marker = new window.google.maps.Marker({
                position: coords,
                map,
                draggable: true,
                title: start?'Start Location':'End Location',
                icon: {
                    url: "https://maps.google.com/mapfiles/ms/icons/"+(start?"red":"blue")+"-dot.png",
                },
            });
            marker.addListener('dragend', () => {
                const position = marker.getPosition();
                setCoordsFunction({ lat: position.lat(), lng: position.lng() });
            });
            marker.addListener('drag', () => {
                const position = marker.getPosition();
                setPosFunctions({ lat: position.lat(), lng: position.lng() });
            });
            setFunction(marker);
            markers[ (start?0:1) ] = true;
        }
        if(setCoords) setCoordsFunction(coords);
        // position map
        // if(markers.every(element => element === true)) setMapToShowBothMarkers(map, startCoords, endCoords)
        // else map.panTo({ lat, lng });
        map.panTo(coords);
    };
    const drawLine = (start, end)=>{
        if(line) line.setMap(null);
        line = new window.google.maps.Polyline({
            path: [start, end],
            geodesic: false,
            strokeColor: 'green', // Red for the trip route
            strokeOpacity: 1.0,
            strokeWeight: 4,
        });
        line.setMap(map);
    }
    const doShowFull = () => {
        // Create a LatLngBounds object
        const bounds = new window.google.maps.LatLngBounds();
    
        // Add the start marker's position to the bounds
        if (startCoords) bounds.extend(startCoords);
    
        // Add the end marker's position to the bounds
        if (endCoords) bounds.extend(endCoords);
    
        // Fit the map's viewport to the markers' bounds
        map.fitBounds(bounds);
    };

    // reset marker count & init map
    useEffect(()=>{
        markers = [null, null];
        map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 0, lng: -30 }, // Default center
            zoom: 1,
            disableDefaultUI: true,
            zoomControl: true,
            mapTypeControl: true
        });
        map.addListener('click', handleMapClick);
    },[])
    
    // place/move markers when place is chosen from search
    const zoom = 12;
    useEffect(()=>{
        if(startCoords){
            setMarkerPosition(startCoords, true, false);
            if(endCoords) drawLine(startCoords,endCoords);
            else if(line) line.setMap(null);
            map.setCenter(startCoords);
            map.setZoom((map.zoom<zoom)?zoom:map.zoom);
        }
        else if(startMarkerInstance){
            startMarkerInstance.setMap(null);
            setStartMarkerInstance(null);
            markers[0] = false;
            if(line) line.setMap(null)
            if(startCircle){
                startCircle.setMap(null);
                setStartCircle(null);
            }
        }
        setStartPos(startCoords);
    },[startCoords])
    useEffect(()=>{
        if(endCoords){
            setMarkerPosition(endCoords, false, false)
            if(startCoords) drawLine(startCoords,endCoords);
            else if(line) line.setMap(null);
            map.setCenter(endCoords);
            map.setZoom((map.zoom<zoom)?zoom:map.zoom);
        }
        else if(endMarkerInstance){
            endMarkerInstance.setMap(null);
            setEndMarkerInstance(null);
            markers[1] = false;
            if(line) line.setMap(null);
            if(endCircle){
                endCircle.setMap(null)
                setEndCircle(null)
            }
        }
        setEndPos(endCoords);
    },[endCoords])

    // line update on drag
    useEffect(()=>{
        if(startPos && endPos) drawLine(startPos,endPos);
    },[startPos, endPos])
    
    // change radius
    useEffect(() => {
        if (map && startMarkerInstance && startRadius) {
            // Draw or update the circle around the start marker
            if (startCircle) {
                startCircle.setRadius(startRadius * 1000); // Convert km to meters
                startCircle.setCenter(startMarkerInstance.getPosition());
            } else {
                const newStartCircle = new window.google.maps.Circle({
                    map,
                    center: startMarkerInstance.getPosition(),
                    radius: startRadius * 1000, // Convert km to meters
                    fillColor: '#FF0000',
                    fillOpacity: 0.2,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                });
                setStartCircle(newStartCircle);
            }
        }
        if (map && endMarkerInstance && endRadius) {
            // Draw or update the circle around the end marker
            if (endCircle) {
                endCircle.setRadius(endRadius * 1000); // Convert km to meters
                endCircle.setCenter(endMarkerInstance.getPosition());
            } else {
                const newEndCircle = new window.google.maps.Circle({
                    map,
                    center: endMarkerInstance.getPosition(),
                    radius: endRadius * 1000, // Convert km to meters
                    fillColor: '#0000FF',
                    fillOpacity: 0.2,
                    strokeColor: '#0000FF',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                });
                setEndCircle(newEndCircle);
            }
        }
    }, [startRadius, endRadius, startCoords, endCoords, startPos, endPos]);

    return(
        <section className='text-center'>
            <div ref={mapRef} className='w-full h-[300px]'/>
            {(showFull && startMarkerInstance && endMarkerInstance) && 
                <div className='btn btn-secondary rounded-full mt-4' onClick={doShowFull}>
                    Show full route
                </div>
            }
        </section>
        
    )
}

export default MapComponent;
