import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { calcNewDateWithLocation } from 'utils/timeUtils';
import keys from 'assets/keys.json';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import LocationInput from './components/LocationInput';
import MapComponent from './components/MapComponent';
import DateInput from './components/DateInput';
import TimeInput from './components/TimeInput';
import SubmitButton from './components/SubmitButton';
import DescriptionInput from './components/DescriptionInput'; // Import Description Input

const TripCreate = () => {
  const [startLocationName, setStartLocationName] = useState('');
  const [endLocationName, setEndLocationName] = useState('');
  const [startCoords, setStartCoords] = useState('');
  const [endCoords, setEndCoords] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [startRadius, setStartRadius] = useState('');
  const [endRadius, setEndRadius] = useState('');
  const [description, setDescription] = useState(''); // New state for description
  const [loading, setLoading] = useState(false); // State for loading status
  const [err, setErr] = useState();

  const db = getFirestore(); // Initialize Firestore
  const navigate = useNavigate(); // Initialize navigate function

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submission starts

    const getUTCOffsetInHours = (lat, lng, oldtimestamp) => {
      return new Promise(async (resolve, reject) => {
        const API_KEY = keys.maps;
        const timestamp = Math.floor(oldtimestamp / 1000);
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${API_KEY}`
          );
          const data = await response.json();
          if (data.status === 'OK') {
            const offsetInSeconds = data.rawOffset + data.dstOffset;
            const offsetInHours = offsetInSeconds / 3600;
            resolve({
              offsetInHours: offsetInHours,
              timeZoneId: data.timeZoneId,
            });
          } else {
            reject(new Error('Error fetching time zone data: ' + data.status));
          }
        } catch (error) {
          reject(new Error('Failed to fetch time zone data: ' + error.message));
        }
      });
    }

    try {
        // get new date
        const [hours, minutes] = time.split(':');
        date.setHours(hours);
        date.setMinutes(minutes);
        const { lat, lng } = startCoords;
        const { offsetInHours, timeZoneId } = await getUTCOffsetInHours(lat, lng, date.getTime());  
        const newDate = calcNewDateWithLocation(date, timeZoneId);

        const data={
            createdAt: Date.now(),
            uid: getAuth().currentUser.uid,
            timestamp: newDate.getTime(),
            offsetInHours: offsetInHours,
            timeZoneId: timeZoneId,
            startLocationName: startLocationName,
            endLocationName: endLocationName,
            startCoords: startCoords,
            endCoords: endCoords,
            startRadius: Number(startRadius),
            endRadius: Number(endRadius),
            description: description
        }        
        // Add trip to Firestore
        const tripsCollection = collection(db, 'trips');
        const docRef = await addDoc(tripsCollection, data);

        // Navigate to trip details page after successful creation
        navigate(`/trip-details/${docRef.id}`);
    }
    catch (error) {
        setErr(error);
        console.error('Error creating trip:', error);
    }
    finally {
        setLoading(false); // Set loading to false when submission is done
    }
  }

  return (
    <div className="p-[30px] flex flex-col items-center justify-center bg-gray-100">
      <form onSubmit={handleSubmit} className="w-full max-w-[800px] bg-white p-8 rounded shadow-md">
        {/* Map Component */}
        <MapComponent
          setStartCoords={setStartCoords}
          setEndCoords={setEndCoords}
          startCoords={startCoords}
          endCoords={endCoords}
          startRadius={startRadius}
          endRadius={endRadius}
          showFull={true}
        />
        <div className="m-[20px]" />
        {/* Start Location Input */}
        <LocationInput
          label="Start Location"
          locationName={startLocationName}
          setLocationName={setStartLocationName}
          coords={startCoords}
          setCoords={setStartCoords}
          setRadius={setStartRadius}
          radius={startRadius}
        />

        {/* End Location Input */}
        <LocationInput
          label="End Location"
          locationName={endLocationName}
          setLocationName={setEndLocationName}
          coords={endCoords}
          setCoords={setEndCoords}
          radius={endRadius}
          setRadius={setEndRadius}
        />

        {/* Date Input */}
        <DateInput setDate={setDate} />
        <TimeInput time={time} setTime={setTime} />

        {/* Description Input */}
        <DescriptionInput description={description} setDescription={setDescription} />

        {/* Submit Button */}
        {err && <div className='alert alert-error mb-3'>{err}</div>}
        <SubmitButton buttonText="Create Trip" disabled={loading} /> {/* Disable button during loading */}
      </form>
    </div>
  )
};

export default TripCreate;
